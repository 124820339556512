import React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import styles from './CategoryCard.styles';

const useStyles = makeStyles(theme => styles(theme));

export default function CategoryCard({ name, image, link }) {
  const classes = useStyles();

  return <Grid item xs={12} sm={6} md={4}>
    <Link to={link} style={{backgroundImage: 'url(' + image + ')'}} className={`image-link ${classes.categoryCard}`} >
        <div className="text-wrap">
            <span className="text">{name}</span>
        </div>
    </Link>
  </Grid>
}