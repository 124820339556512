import React from 'react';
import ReactHtmlParser from "react-html-parser";
import Img from 'gatsby-image';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

import styles from './ProductCard.styles';

const useStyles = makeStyles(theme => styles(theme));

export default function ProductCard({ id, code, link, linkState, brands, title, description, wishClass, wishlistHandler, children }) {
  function Logo(props) {
    if (!props.brands || !props.brands.nodes.length) return null;
  
    if(props.brands.nodes[0].BrandExtras.logo === null) return null;

    if (props.brands.nodes[0].BrandExtras.logo.localFile) {
      return <Img className="brand-logo" fluid={props.brands.nodes[0].BrandExtras.logo.localFile.childImageSharp.fluid} />
    }

    if (props.brands.nodes[0].BrandExtras.logo.sourceUrl) {
      return <img className="brand-logo" src={props.brands.nodes[0].BrandExtras.logo.sourceUrl} />
    }

    return null;
  }

  const classes = useStyles();

  return <Grid item xs={12} sm={6} lg={4} className={classes.productCard}>
    <button onClick={() => wishlistHandler(String(code))} className={"add-wishlist " + wishClass}>Quote Builder</button>
    <Link to={link} state={linkState} className="product-item">
      <Logo brands={brands} />
      {children}
      <span className="product-title">{title}</span>
      <span className="product-desc">{ ReactHtmlParser(description) }</span>
    </Link>
  </Grid>;
}