import React, {Component} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import ProductSearch from '../components/ProductSearch';

class Page extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const prefill = this.props.location.state && this.props.location.state.query
    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: '/product-search/'
        }}
        path={'a'}
        cta={'a'}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <ProductSearch prefill={prefill} />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    wpPage(uri: {eq: "/"}) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page
