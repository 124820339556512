import pageTitle from '../../images/page-title.png';
import icon from "../../images/search.png";
import searching from '../../images/searching.gif';

export default theme => ({
    productSearchTitle: {
        position: 'relative',
        marginBottom: 36,
        padding: '32px 0',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            padding: '84px 0',
        },
        '& .heading': {
            flex: '0 0 100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                maxWidth: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 56.25%',
                maxWidth: '56.25%',
            }
        },
        '& .search': {
            flex: '0 0 100%',
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                maxWidth: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 43.75%',
                maxWidth: '43.75%',
            }
        },
        '& .current-location': {
            padding: '16px 90px 0 40px',
            color: theme.palette.common.white,
            background: 'transparent',
            border: 0
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
            color: theme.palette.primary.contrastText,
            marginBottom: 0,
            position: 'relative',
            lineHeight: 1.2,
        },
        '&::before': {
            display: 'block',
            width: '100vw',
            position: 'absolute',
            zIndex: -1,
            content: '""',
            top: 0,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundImage: 'url(' + pageTitle + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        '& form': {
            position: 'relative',
            '& label': {
                position: 'absolute',
                top: 12,
                left: 31,
                opacity: .3,
                textTransfrom: 'uppercase',
                color: 'white',
                textTransform: 'uppercase',
                fontSize: 8,
                letterSpacing: '0.152em',
                lineHeight: 1.2,
                [theme.breakpoints.up('md')]: {
                    top: 16,
                    left: 41,
                },
            },
            '& input[type="text"]': {
                border: '1px solid white',
                borderRadius: 28,
                padding: '28px 60px 10px 30px',
                display: 'block',
                margin: 0,
                width: '100%',
                lineHeight: 1,
                fontSize: 12,
                color: 'white',
                backgroundColor: 'transparent',
                fontFamily: theme.bodyFont,
                letterSpacing: '0.032em',
                outline: 'none',
                [theme.breakpoints.up('md')]: {
                    padding: '32px 90px 16px 40px',
                    fontSize: 16,
                    borderRadius: 36,
                },
                '& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder': {
                    color: 'white',
                    opacity: 1,
                }
            },
            '& input[type="submit"]': {
                position: 'absolute',
                outline: 'none',
                top: 11,
                right: 11,
                width: 32,
                height: 32,
                border: 'none',
                backgroundColor: 'transparent',
                borderRadius: '50%',
                fontSize: 0,
                backgroundPosition: 'center',
                backgroundImage: 'url('+icon+')',
                backgroundSize: 'cover',
                [theme.breakpoints.up('md')]: {
                    width: 49,
                    height: 49,
                },
            }
        }
    },
    queryTitle: {
        fontSize: 24,

        '& > span': {
            color: '#555'
        }
    },
    productResults: {
        marginBottom: 32,
        '& h4': {
            color: '#999',
            fontSize: 14
        },
        '& .MuiGrid-grid-lg-4': {
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 25% !important',
                maxWidth: '25% !important',
            }
        },
    },
    categoryResults: {
        marginBottom: 32,
        '& h4': {
            color: '#999',
            fontSize: 14
        }
    },
    searching: {
        padding: 72,
        paddingRight: 0,
        backgroundImage: 'url(' + searching + ')',
        display: 'block',
        width: 'fit-content',
        margin: '0 auto',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        fontSize: 18,
    },
});