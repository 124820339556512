import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../Link';
import styles from './styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => styles(theme));

export default function Breadcrumb(props) {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
    query breadcrumbItems {
      wp {
        themeOptions {
          blogPath
          blogPathTitle
        }
      }
    }
  `);

    return (
        <div className={`breadcrumb ${classes.breadcrumb} ${classes.breadcrumbTheme}`}>
            <Breadcrumbs 
                maxItems={2} 
                itemsAfterCollapse={2}
                itemsBeforeCollapse={0}
                separator=""
                aria-label="breadcrumbs"
            >
                <Link to="/">Home</Link>

                {props.type === "page" && props.ancestors !== null && props.ancestors.nodes.map(link => {
                    return <Link to={link.uri} key={link.id}>{link.title}</Link>
                })}

                {(props.type === 'post' || props.type === 'post-list') && <Link to="/expertise/">Expertise</Link> }
                {props.type === 'post' &&
                    <Link to={'/expertise/' + data.wp.themeOptions.blogPath}>{data.wp.themeOptions.blogPathTitle}</Link>
                }

                {props.type === 'news-post' &&
                    <Link to='/latest-news/'>Latest News</Link>
                }

                {props.type === 'brand' &&
                    <Link to="/about-us/">About us</Link>
                }

                {props.type === 'category' && props.ancestors &&
                    <Link to={props.ancestors.uri}>{props.ancestors.name}</Link>
                }

                {props.type === "product" && props.ancestors !== null && props.ancestors.map(link => {
                    return <Link to={"/category/" + link.slug} key={link.id}>{link.name}</Link>
                })}

                {(props.type === 'how-to' || props.type === 'how-to-listing') && <Link to="/expertise/">Expertise</Link> }
                {props.type === 'how-to' && <Link to="/expertise/how-to/">How To</Link> }

                <Typography>{props.current.title}</Typography>
            </Breadcrumbs>
        </div>
    )
}