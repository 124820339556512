import wishlist from "../../../images/basket-regular.png";
import wishlistFilled from "../../../images/basket-filled.png";
import arrow from "../../../images/button-arrow-pink.png";

export default (theme) => ({
  productCard: {
    "& .add-wishlist": {
      float: "right",
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      position: "relative",
      padding: 0,
      fontSize: 10,
      fontFamily: theme.bodyFont,
      color: theme.palette.primary.main,
      letterSpacing: "0.152em",
      fontWeight: 500,
      textTransform: "uppercase",
      lineHeight: 2.4,
      marginTop: 20,
      marginRight: 20,
      zIndex: 2,
      "&::after": {
        content: '""',
        width: 20,
        height: 19,
        backgroundImage: "url(" + wishlist + ")",
        display: "inline-block",
        backgroundSize: "contain",
        marginLeft: 6,
        verticalAlign: "top",
      },
      "&.added": {
        "&::after": {
          backgroundImage: "url(" + wishlistFilled + ")",
        },
      },
    },
    "& .product-item": {
      backgroundColor: theme.palette.primary.contrastText,
      border: "1px solid #BAC2CE",
      display: "block",
      padding: "72px 30px 20px 30px",
      textDecoration: "none",
      position: "relative",
      backgroundImage: "url(" + arrow + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom 30px right 30px",
      "& .brand-logo": {
        maxWidth: "120px !important",
        position: "absolute !important",
        top: 10,
        left: 0,
        width: 120,
      },
      "& .product-image": {
        display: "block",
        maxWidth: "185px !important",
        maxHeight: "185px !important",
        margin: "0 auto 32px !important",
      },
      "& .product-title": {
        fontWeight: "700",
        letterSpacing: "0.08em",
        overflow: "hidden",
        paddingBottom: 6,
        lineHeight: 1.5,
        height: 54,
        borderBottom: "1px solid #BAC2CE",
        display: "block",
        color: theme.palette.primary.main,
      },
      "& .product-desc": {
        color: theme.palette.primary.main,
        paddingTop: 16,
        paddingRight: 90,
        display: "block",
        fontSize: 10,
        letterSpacing: "0.008em",
        lineHeight: 1.6,
        height: 64,
        overflow: "hidden",
      },
    },
  },
});
