export default theme => ({
    breadcrumb: {
        position: 'relative',
        padding: '13px 0',
        boxShadow: '-250px 0 white, 250px 0 white',
        zIndex: 2,
        '& nav': {
            '& li': {
                fontSize: 0,
                whiteSpace: 'none',
                '& a, & p': {
                    fontSize: 10,
                    lineHeight: 1.6,
                    textTransform: 'uppercase',
                    letterSpacing: '0.152em',
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                },
                '& p': {
                    fontWeight: 500,
                },
                '& a': {
                    opacity: 0.3,
                    '&::after': {
                        content: '">"',
                        marginLeft: 10,
                    }
                },
                '&.MuiButtonBase-root': {
                    backgroundColor: 'transparent',
                    '&::before': {
                        content: '"<<"',
                        fontSize: 10,
                        lineHeight: 1.6,
                        textTransform: 'uppercase',
                        letterSpacing: '0.152em',
                        textDecoration: 'none',
                        color: theme.palette.primary.main,
                        fontWeight: 500,
                    },
                    '& svg': {
                        display: 'none',
                    }
                }
            }
        }
    },
    breadcrumbTheme: {
        ...theme.breadcrumbTheme,
    }
});