import linkArrow from '../../images/button-arrow-white.png';


export default theme => ({
  categoryCard: {
    display: 'block',
    width: '100%',
    paddingBottom: '100%',
    padding: 20,
    boxShadow: '0 6px 10px rgba(0,0,0,0.08)',
    color: 'white',
    backgroundColor: theme.palette.secondary.dark,
    textDecoration: 'none !important',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    '&:hover, &:focus': {
        textDecoration: 'none',
        '& svg': {
            marginLeft: 24,
        }
    },
    '&::after': {
        width: 70,
        height: 70,
        bottom: 0,
        position: 'absolute',
        right: 0,
        backgroundImage: 'url(' + linkArrow + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        content: '""',
    },
    '& .text-wrap': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 70,
        backgroundColor: theme.palette.primary.contrastText,
        padding: '22px 32px',
        '& .text': {
            fontFamily: theme.headerFont,
            fontSize: 18,
            lineHeight: 1.5,
            letterSpacing: '0.008em',
            position: 'relative',
            zIndex: '1',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
        '& .sub-text': {
            fontFamily: theme.subHeaderFont,
            fontSize: 14,
            lineHeight: '1.5',
            letterSpacing: '0.038em',
            fontWeight: '600',
            position: 'relative',
            zIndex: '1',
            display: 'block',
            color: theme.palette.secondary.main,
        },
    },

  }
});